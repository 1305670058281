<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'auth-final-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template v-if="elementData && elementData.service_type && serviceBundleList && clinicalLaboratoryList">
      <element-edit-tab
        :element-data="elementData"
        :service-bundle-list="serviceBundleList"
        :holidays="holidays"
        :destination-orders-list="destinationOrdersList"
        :clinical-laboratory-list="clinicalLaboratoryList"
        :clinical-laboratory-department-list="clinicalLaboratoryDepartmentList"
        :clinical-laboratory-city-list="clinicalLaboratoryCityList"
        :clinical-headquarter-list="clinicalHeadquarterList"
        :clinical-sample-laboratory-city-list="clinicalSampleLaboratoryCityList"
        :clinical-sample-headquarter-list="clinicalSampleHeadquarterList"
        class="mt-2 pt-75"
      />
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '@/views/service_auth_final/storeModule'
import ElementEditTab from './EditCard.vue'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-freezer-scheduled-view-order',
      RESOURCES_ELEMENT_NAME: 'arl_codes',
      newElement: true,
      serviceBundleList: [],
      holidays: [],
      destinationOrdersList: [],
      elementData: undefined,

      clinicalLaboratoryList: undefined,
      clinicalLaboratoryDepartmentList: [],
      clinicalLaboratoryCityList: [],
      clinicalHeadquarterList: [],
      clinicalSampleLaboratoryCityList: [],
      clinicalSampleHeadquarterList:[]
    }
  },
  beforeDestroy () {
    this.$root.$off('fetch-clinical-cities')
    this.$root.$off('fetch-clinical-laboratory-headquarters')
    this.$root.$off('fetch-clinical-sample-cities')
    this.$root.$off('fetch-clinical-sample-laboratory-headquarters')
  },
  async mounted() {
    var _self = this
    this.$root.$on('fetch-clinical-cities', async (department_id) => {
      _self.clinicalLaboratoryCityList = await _self.fetch_key_value('geography', 3, department_id)
    })
    this.$root.$on('fetch-clinical-laboratory-headquarters', async (company_id) => {
      _self.clinicalHeadquarterList = company_id ? await _self.fetch_headquarters(company_id) : []
    })
    this.$root.$on('fetch-clinical-sample-cities', async (department_id) => {
      _self.clinicalSampleLaboratoryCityList = await _self.fetch_key_value('geography', 3, department_id)
    })
    this.$root.$on('fetch-clinical-sample-laboratory-headquarters', async (company_id) => {
      _self.clinicalSampleHeadquarterList = company_id ? await _self.fetch_headquarters(company_id) : []
    })
    // Register module
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, elementStoreModule)
    
    this.elementData = await this.fetchElement()
    this.serviceBundleList = await this.fetch_service_bundles()
    var hols = await this.fetchHolidays()

    this.holidays = []

    for (const hol of hols) {
      this.holidays.push(moment(hol.date).startOf('day'))
    }

    this.destinationOrdersList = await this.fetch_radio_key_value('destination_order')

    this.clinicalLaboratoryDepartmentList = await this.fetch_key_value('geography', 2)
    this.clinicalLaboratoryList = await this.fetch_companies()
    switch (this.elementData.service_type.key) {
      case 'VACCINATION':
        this.clinicalLaboratoryList = this.clinicalLaboratoryList.filter(element => element.supplier_type_key === 'VACCINE') 
        break
      default:
        this.clinicalLaboratoryList = this.clinicalLaboratoryList.filter(element => element.supplier_type_key === 'LABORATORY') 
        break
    }
    
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_companies() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_companies', {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: '',
            companyType: 'SUPPLIER'
          }
        })
        .then(response => {
          return response.data.items
        })
        .catch(() => {
          return []
        })
    },
    async fetch_headquarters(company_id) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_headquarters', {
          company_id: company_id
        })
        .then(response => {
          return response.data
        })
        .catch(() => {
          return []
        })
    },
    async fetch_radio_key_value(type, deep, parent) {
      var _self = this
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type, deep: deep, parent: parent }).then(response => {
        var items = response.data.items
        for (const it of items.filter(element => element.key.toLowerCase().startsWith('other'))) {
          it.has_other = true
          it.other_field = 'other_' + it.key.toLowerCase() + '_' +it.id
          it.other_label = this.$t(_self.RESOURCES_ELEMENT_NAME + '.object.which')
        }
        return items
      }).catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          return []
        }
      })
    },
    async fetch_key_value(type, deep, parent) {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type, deep: deep, parent: parent }).then(response => {
        var list = response.data.items
        list.unshift({ id: -1, key: this.$t('list.defaultOption'), value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    async fetch_service_bundles() {
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_service_bundles', { arl_id: this.elementData.arl_id }).then(response => {
        var list = response.data
        list.unshift({ id: null, bundle_name_value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          return undefined
        })
    },
    async fetchHolidays() {
      this.newElement = false
      return store.dispatch(this.ARL_CODES_APP_STORE_MODULE_NAME + '/fetch_holidays')
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          return undefined
        })
    },
  }
}
</script>

<style>

</style>
