<template>
  <div>
    <b-form
      @submit.prevent="save"
    >
      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Principal</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="number"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.number') }}</span>
                  <b-form-input
                    id="number"
                    v-model="elementData.id"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="date"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.date') }}</span>
                  <b-form-input
                    id="date"
                    v-model="dateParsed"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="service_type"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.service_type') }}</span>
                  <b-form-input
                    id="service_type"
                    v-model="elementData.service_type.value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="expireDate"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.date') }}</span>
                  <b-form-input
                    id="date"
                    v-model="scheduledDateParsed"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col
              cols="4"
            >
              <date-picker :element-required="true" element-field="scheduled_date" :root-element-data="elementData" :element-label="$t(resourcesElementName + '.object.date')"/>
            </b-col> -->

              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="expireDate"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.expireDate') }}</span>
                  <b-form-input
                    id="expireDate"
                    v-model="expireDateParsed"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="medical_order_status"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.medical_order_status') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.medical_order_status')"
                  >
                    <b-form-input
                      id="medical_order_status"
                      v-model="elementData.medical_order_status.value"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="case_number"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.case_number') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.case_number')"
                  >
                    <b-form-input
                      id="case_number"
                      v-model="elementData.case_number"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="arl_name"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.arl')"
                  >
                    <b-form-input
                      id="arl_name"
                      v-model="elementData.arl_name"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="internal_code"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.internal_code') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.internal_code')"
                  >
                    <b-form-input
                      id="internal_code"
                      v-model="elementData.internal_code"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card-body>
        </b-card>
        <div v-if="elementData.service_type.key=='LABORATORIES'">
          <b-card>
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary"
                />
                <span class="d-none d-sm-inline">Información Trabajador</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="patient_identity"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                    <b-form-input
                      id="patient_identity"
                      v-model="elementData.patient_identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="patient_name"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                    <b-form-input
                      id="patient_name"
                      v-model="elementData.patient_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="patient_birthdate"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_birthdate') }}</span>
                    <b-form-input
                      id="patient_birthdate"
                      v-model="birthdate"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="patient_age"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_age') }}</span>
                    <b-form-input
                      id="patient_age"
                      v-model="age"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>

            <div v-if="elementData.request_destination.key=='SOURCE'">
              <b-card-header>
                <div class="text-primary h2">
                  <feather-icon
                    icon="StarIcon"
                    size="25"
                    class="mr-0 mr-sm-50"
                    variant="primary"
                  />
                  <span class="d-none d-sm-inline">Información fuente</span>
                </div>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      label-for="source_identity"
                    >
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_identity') }}</span>
                      <b-form-input
                        id="source_identity"
                        v-model="elementData.case.source_identity"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      label-for="source_name"
                    >
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_name') }}</span>
                      <b-form-input
                        id="source_name"
                        v-model="source_name"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      label-for="source_birthdate"
                    >
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_birthdate') }}</span>
                      <b-form-input
                        id="source_birthdate"
                        v-model="sourceBirthdate"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      label-for="source_age"
                    >
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_age') }}</span>
                      <b-form-input
                        id="source_age"
                        v-model="sourceAge"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
            <b-card-body>
              <b-row>
                <b-col
                  cols="6"
                >
                  <b-form-group
                    label-for="request_destination"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.request_destination') }}</span>
                    <b-form-input
                      id="request_destination"
                      v-model="elementData.request_destination.value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="6"
                >
                  <b-form-group
                    label-for="element-status"
                  >
                    <b-form-checkbox
                      v-model="elementData.requires_freezer"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      inline
                      value="1"
                      unchecked-value="0"
                      disabled
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.requires_freezer') }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
        <div v-else>
          <b-card>
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary"
                />
                <span class="d-none d-sm-inline">Información Trabajador</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="patient_identity"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                    <b-form-input
                      id="patient_identity"
                      v-model="elementData.patient_identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="patient_name"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                    <b-form-input
                      id="patient_name"
                      v-model="elementData.patient_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="patient_birthdate"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_birthdate') }}</span>
                    <b-form-input
                      id="patient_birthdate"
                      v-model="birthdate"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="patient_age"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_age') }}</span>
                    <b-form-input
                      id="patient_age"
                      v-model="age"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>

        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Laboratorio</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_department"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_department') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_department')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_department_id"
                      :options="clinicalLaboratoryDepartmentList"
                      value-field="id"
                      text-field="value"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onDepartmentChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_city"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_city') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_city')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_city_id"
                      :options="clinicalLaboratoryCityList"
                      value-field="id"
                      text-field="value"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onCityChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_laboratory_lab"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_lab') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_laboratory_lab')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_laboratory_id"
                      :options="localClinicalLaboratoryList"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onLaboratoryChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_laboratory_headquarter"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_laboratory_headquarter')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_laboratory_headquarter_id"
                      :options="clinicalHeadquarterList"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onHeadquarterChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="8"
              >
                <b-form-group
                  label-for="clinical_laboratory_headquarter_address"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter_address') }}</span>
                  <b-form-input
                    id="clinical_laboratory_headquarter_address"
                    v-model="address"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="elementData.service_type.key=='LABORATORIES'">
              <b-col
                cols="6"
                md="6"
              >
                <b-form-group
                  label-for="element-status"
                >
                  <b-form-checkbox
                    v-model="elementData.same_laboratory"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    value="1"
                    unchecked-value="0"
                    disabled
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.same_laboratory') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card v-if="elementData.same_laboratory==0">
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Laboratorio procesamiento de la muestra</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_sample_department"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_department') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_department')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_sample_department_id"
                      :options="clinicalLaboratoryDepartmentList"
                      value-field="id"
                      text-field="value"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onSampleDepartmentChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_sample_city"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_city') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_city')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_sample_city_id"
                      :options="clinicalSampleLaboratoryCityList"
                      value-field="id"
                      text-field="value"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onSampleCityChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_sample_laboratory_lab"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_lab') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_laboratory_lab')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_sample_laboratory_id"
                      :options="localClinicalSampleLaboratoryList"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onSampleLaboratoryChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="clinical_sample_laboratory_headquarter"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_laboratory_headquarter')"
                  >
                    <b-form-select
                      v-model="elementData.clinical_sample_laboratory_headquarter_id"
                      :options="clinicalSampleHeadquarterList"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      :disabled="true"
                      @change="onSampleHeadquarterChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="8"
              >
                <b-form-group
                  label-for="clinical_sample_laboratory_headquarter_address"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter_address') }}</span>
                  <b-form-input
                    id="clinical_laboratory_headquarter_address"
                    v-model="sample_address"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <groups-tab
            :element-data="elementData"
            :service-bundle-list="serviceBundleList"
          />
        </b-card>

        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="router().go(-1)"
          >
            {{ $t('actions.back') }}
          </b-button>
        </b-row>
      </validation-observer>
    </b-form>

  </div>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import router from '@/router'
import GroupsTab from './EditGroups.vue'

export default {
  components: {
    RadioSelectPicker, GroupsTab, BFormTextarea, DatePicker, BFormCheckbox, BCard, BButton, BRow, BCol, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryDepartmentList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    clinicalHeadquarterList: {
      type: Array,
      required: true,
    },

    clinicalSampleLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalSampleHeadquarterList: {
      type: Array,
      required: true,
    },
    holidays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: '',
      vigence_date_render: '',
      required,
      dateParsed: undefined,
      expireDateParsed: undefined,
      scheduledDateParsed: undefined,
      birthdate: '',
      sourceBirthdate: '',
      address: '',
      localClinicalLaboratoryList: [],
      localClinicalSampleLaboratoryList: [],
      sample_address: '',
    }
  },
  computed: {
    age: {
      get() {
        if (this.elementData.case.patient.birthdate) {
          let date = this.elementData.case.patient.birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
    sourceAge: {
      get() {
        if (this.elementData.case.source_birthdate) {
          let date = this.elementData.case.source_birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
    source_name: {
      get() {
        return `${this.elementData.case.source_first_name} ${this.elementData.case.source_second_name} ${this.elementData.case.source_first_surname} ${this.elementData.case.source_second_surname}`
      },
    },
  },
  watch: {
    'elementData.authorization_date': function (newValue) {
      this.update_vigence_date()
    },
    'elementData.scheduled_date': function (newValue) {
      this.update_schedule_date()
    },
  },
  mounted() {
    this.expireDateParsed = undefined
    this.scheduledDateParsed = undefined
    this.resourcesElementName = 'arl_codes'
    this.localClinicalLaboratoryList = this.clinicalLaboratoryList.filter(element => element.city_id == this.elementData.clinical_city_id)

    const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_laboratory_id)
    this.address = laboratory ? laboratory.address : ''
    this.address = this.elementData.clinical_laboratory_headquarter_id ? this.clinicalHeadquarterList.find(element => element.id == this.elementData.clinical_laboratory_headquarter_id).address : this.address

    this.localClinicalSampleLaboratoryList = this.clinicalLaboratoryList.filter(element => element.city_id === this.elementData.clinical_sample_city_id)

    const sample_laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_sample_laboratory_id)
    this.sample_address = sample_laboratory ? sample_laboratory.address : ''
    this.sample_address = this.elementData.clinical_sample_laboratory_headquarter_id ? this.clinicalSampleHeadquarterList.find(element => element.id == this.elementData.clinical_sample_laboratory_headquarter_id).address : this.sample_address

    this.$root.$emit('fetch-clinical-cities', this.elementData.clinical_department_id)
    this.$root.$emit('fetch-clinical-laboratory-headquarters', this.elementData.clinical_laboratory_id)
    this.$root.$emit('fetch-clinical-sample-cities', this.elementData.clinical_sample_department_id)
    this.$root.$emit('fetch-clinical-sample-cities', this.elementData.clinical_sample_department_id)
    this.update_schedule_date()
    this.update_vigence_date()
    this.dateParsed = this.formatDate(this.elementData.date)
    this.birthdate = this.formatDate(this.elementData.case.patient.birthdate)
    this.sourceBirthdate = this.formatDate(this.elementData.case.source_birthdate)
  },
  methods: {
    router() {
      return router
    },
    update_schedule_date() {
      this.elementData.expire_date = this.processScheduleDate(this.elementData.scheduled_date)
      this.scheduledDateParsed = this.formatDate(this.elementData.scheduled_date)
      this.expireDateParsed = this.formatDate(this.elementData.expire_date)
    },
    onDepartmentChange() {
      this.elementData.clinical_city_id = null
      this.$root.$emit('fetch-clinical-cities', this.elementData.clinical_department_id)
    },
    onCityChange() {
      // Filter laboratory list by city
      this.elementData.clinical_laboratory_id = null
      this.localClinicalLaboratoryList = this.clinicalLaboratoryList.filter(element => element.city_id === this.elementData.clinical_city_id)
    },
    onLaboratoryChange() {
      this.elementData.clinical_laboratory_headquarter_id = null
      this.$root.$emit('fetch-clinical-laboratory-headquarters', this.elementData.clinical_laboratory_id)
      const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_laboratory_id)
      this.address = laboratory ? laboratory.address : ''
    },
    onHeadquarterChange() {
      this.address = this.elementData.clinical_laboratory_headquarter_id ? this.clinicalHeadquarterList.find(element => element.id == this.elementData.clinical_laboratory_headquarter_id).address : this.address
    },

    onSampleDepartmentChange() {
      this.elementData.clinical_sample_city_id = null
      this.$root.$emit('fetch-clinical-sample-cities', this.elementData.clinical_sample_department_id)
    },
    onSampleCityChange() {
      // Filter laboratory list by city
      this.elementData.clinical_sample_laboratory_id = null
      this.localClinicalSampleLaboratoryList = this.clinicalLaboratoryList.filter(element => element.city_id === this.elementData.clinical_sample_city_id)
    },
    onSampleLaboratoryChange() {
      this.elementData.clinical_sample_laboratory_headquarter_id = null
      this.$root.$emit('fetch-clinical-sample-laboratory-headquarters', this.elementData.clinical_sample_laboratory_id)
      const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_sample_laboratory_id)
      this.sample_address = laboratory ? laboratory.address : ''
    },
    onSampleHeadquarterChange() {
      this.sample_address = this.elementData.clinical_sample_laboratory_headquarter_id ? this.clinicalSampleHeadquarterList.find(element => element.id == this.elementData.clinical_sample_laboratory_headquarter_id).address : this.sample_address
    },

    processScheduleDate(newValue) {
      let count = 0
      const date = moment(newValue, 'DD/MM/YYYY')
      while (count < 5) {
        do {
          date.add(1, 'days')
        } while (this.isHoliday(date))
        count += 1
      }
      return date.toDate()
    },
    isHoliday(date) {
      const mdate = moment(date)
      const saturday = moment().isoWeekday('Saturday').weekday()
      const sunday = moment().isoWeekday('Sunday').weekday()
      if (mdate.weekday() == saturday || mdate.weekday() == sunday) {
        return true
      }
      for (const holiday of this.holidays) {
        if (holiday.isSame(mdate)) {
          return true
        }
      }
      return false
    },
    formatDate(value) {
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    update_vigence_date() {
      console.log('updating vigence_date')
      let service_vigency = 0
      if (this.elementData.service_bundle_id && this.serviceBundleList && this.serviceBundleList.length > 0) {
        service_vigency = this.serviceBundleList.find(element => element.id == this.elementData.service_bundle_id).validity
      }
      let authorization_date = moment()
      if (this.elementData.authorization_date) {
        authorization_date = moment(this.elementData.authorization_date)
      }
      this.elementData.vigence_date = authorization_date.add(service_vigency, 'days').toDate()
      this.vigence_date_render = moment(this.elementData.vigence_date).format('DD/MM/YYYY')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('service_type' in this.elementData) {
            delete this.elementData.service_type
          }
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style>

</style>
