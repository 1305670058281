<template>
  <div>
    <b-form-group :label-for="elementLabel">
      <!-- when there is one title for many options, one for row

                [title] [opt one]
                        [opt two]
                        [opt three]
            -->

      <validation-provider
        #default="{ errors }"
        :vid="elementField"
        :name="elementLabel"
        :rules="rules">
        <b-row v-if="rowly">
          <b-col cols="3">
            <feather-icon
              icon="StarIcon"
              class="mr-75" />
            <span class="font-weight-bold radio_title">{{ elementLabel }}</span
            ><span
              v-if="elementRequired"
              class="text-danger"
              >*</span
            >
          </b-col>
          <b-col cols="9">
            <template v-for="item in internElementSelectionList">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="item.checked"
                    class="mr-0 mt-50"
                    inline
                    :disabled="disabled"
                    @change="forceUpdate(item.id, $event)" />
                  <span
                    :id="elementField + item.key"
                    class="font-weight-bold"
                    >{{ item.value }}</span
                  >
                </b-col>
              </b-row>
            </template>
          </b-col>
        </b-row>
        <div v-else>
          <!-- when there is one title for many options begining with the title row

                        [title] [opt one] [opt two]
                                [opt three] [opt four]
                    -->
          <b-row
            v-if="inline"
            class="mt-1">
            <b-col cols="3">
              <feather-icon
                icon="StarIcon"
                class="mr-75" />
              <span class="font-weight-bold radio_title">{{
                elementLabel
              }}</span
              ><span
                v-if="elementRequired"
                class="text-danger"
                >*</span
              >
            </b-col>
            <b-col cols="9">
              <b-row>
                <template v-for="item in internElementSelectionList">
                  <b-col cols="3">
                    <b-form-checkbox
                      v-model="item.checked"
                      class="mr-0 mt-0"
                      inline
                      :disabled="disabled"
                      @change="forceUpdate(item.id, $event)" />
                    <!-- ? CHECKBOX -->
                    <b-tooltip
                      :target="elementField + item.key"
                      :title="$t('item.value')"
                      triggers="hover"
                      :noninteractive="true"
                      placement="topleft">
                    </b-tooltip>
                    <span
                      :id="elementField + item.key"
                      class="font-weight-bold"
                      >{{ getName(item.value) }}</span
                    >
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

          <!--
                        when there is one title for many options after title row

                        [title]
                        [opt one] [opt two]
                        [opt three] [opt four]
                    -->
          <div v-else>
            <b-row class="mt-2">
              <b-col cols="12">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold radio_title">{{
                  elementLabel
                }}</span
                ><span
                  v-if="elementRequired"
                  class="text-danger"
                  >*</span
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <template v-for="item in internElementSelectionList">
                    <b-col cols="3">
                      <b-form-checkbox
                        v-model="item.checked"
                        class="mr-0 mt-50"
                        inline
                        :disabled="disabled"
                        @change="forceUpdate(item.id, $event)" />
                      <b-tooltip
                        :target="elementField + item.key"
                        triggers="hover">
                        <span class="align-middle ml-50">{{ item.value }}</span>
                      </b-tooltip>
                      <span
                        :id="elementField + item.key"
                        class="font-weight-bold"
                        >{{ getName(item.value) }}</span
                      >
                    </b-col>
                    <b-col
                      v-if="show_other(item)"
                      cols="3">
                      <b-form-group :label-for="item.other_field">
                        <feather-icon
                          icon="StarIcon"
                          class="mr-75" />
                        <span class="font-weight-bold">{{
                          item.other_label
                        }}</span>
                        <b-form-input
                          :id="item.other_field"
                          v-model="rootElementData[item.other_field]"
                          maxlength="150"
                          :disabled="disabled" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <br />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BForm,
  BButton,
  BCol,
  BRow,
  BTooltip,
} from 'bootstrap-vue'
import { extend, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BButton,
    BInputGroup,
    BForm,
    BInputGroupAppend,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    ValidationProvider,
    BCol,
    BRow,
    BTooltip,
  },
  props: {
    rootElementData: {
      type: Object,
      required: true,
    },
    elementLabel: {
      type: String,
      required: true,
    },
    elementField: {
      type: String,
      required: true,
    },
    elementRequired: {
      type: Boolean,
      required: true,
    },
    elementSelectionList: {
      type: Array,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    rowly: {
      type: Boolean,
      required: false,
      default: false,
    },
    exec: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internElementSelectionList: [],
      value: undefined,
    }
  },
  computed: {
    rules: {
      get() {
        return this.elementRequired
          ? `custom_value_required:${this.elementField}`
          : undefined
      },
    },
  },
  watch: {
    elementSelectionList(val) {
      this.loadArray()
    },
  },
  mounted() {
    this.loadArray()
    this.value = this.rootElementData[this.elementField]
  },
  created() {
    extend('custom_value_required', {
      message: (field, element) => `El campo ${field} es requerido.`,
      validate: (value, params) => {
        const data = this.rootElementData[params[0]]
        return !!data
      },
    })
  },
  methods: {
    show_other(item) {
      return (
        item.has_other && this.rootElementData[this.elementField] == item.id
      )
    },
    getName(name) {
      let max_length = 0
      if (this.inline) {
        max_length = 18
      } else {
        max_length = 24
      }
      return name.length > max_length
        ? `${name.substring(0, max_length - 3)}...`
        : name
    },
    forceUpdate(id, event) {
      this.rootElementData[this.elementField] = ''
      for (const element of this.internElementSelectionList) {
        if (event && element.id == id) {
          this.rootElementData[this.elementField] = id
          this.value = id
        }
      }
      this.loadArray()
      if (this.exec) {
        this.exec()
      }
    },
    loadArray() {
      this.internElementSelectionList = []
      for (const element of this.elementSelectionList) {
        const newElement = {
          checked: this.rootElementData[this.elementField] == element.id,
        }
        Object.assign(newElement, element)
        this.internElementSelectionList.push(newElement)
      }
    },
  },
}
</script>
<style>
.radio_title {
  text-decoration: underline;
  font-weight: bolder !important;
}
</style>
